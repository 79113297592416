import React from 'react';
import i18n from 'i18next';
import { apiCalls } from '../apis/api-calls';
import { setIntermediaryBranding } from '../components/base/util/Branding.util';
import WaitModal from '../components/base/dialog/wait-modal/WaitModal';

const initialConfiguration = {
  loaded: false,
  intermediaryData: {},
  intermediaryBranding: {},
  brokerData: {},
  simplicityConfig: {}
};

const UserInfoContext = React.createContext();

export function UserInfoProvider({ children }) {
  const [userInfo, setUserInfo] = React.useState(initialConfiguration);
  if (!userInfo.loaded) {
    apiCalls.getUserInfo().then((userInfo) => {
      setUserInfo({ loaded: true, ...userInfo });
      const defaultLanguage = userInfo.broker.defaultLanguage || userInfo.intermediary.defaultLanguage;
      if (defaultLanguage === 'de' || defaultLanguage === 'en') {
        i18n.changeLanguage(defaultLanguage);
      }
      setIntermediaryBranding(userInfo.intermediary);
    });
  }
  return (
    <UserInfoContext.Provider value={userInfo}>
      {userInfo.loaded ? children : <WaitModal text='Smart Portal' closeButton={false} />}
    </UserInfoContext.Provider>
  );
}

export function useUserInfo() {
  const context = React.useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error('useUserInfo() must be used within a UserInfoProvider');
  }
  return context;
}
