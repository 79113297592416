import React, { Suspense } from 'react';
import { ThemeProvider } from '@myeh/design-system';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { OidcSecure } from '@axa-fr/react-oidc';
import { Trans } from 'react-i18next';
import asyncLoadingComponent from './components/base/util/AsyncLoadingComponent.util';
import OidcProvider from './components/smart-portal/oidc/oidc-provider';
import { UserInfoProvider } from './config/portal-config';
import './App.css';
import PermissionsProvider from './components/smart-portal/permissions/PermissionsProvider.js';
import AdobeAnalytics from './components/smart-portal/common/adobe-analytics/AdobeAnalytics';

const AsyncCustomerPortal = asyncLoadingComponent(() =>
  import('./components/smart-portal/portal/portal-app/PortalApp')
);

function App() {
  return (
    <Suspense fallback={<div>Loading... </div>}>
      <PermissionsProvider>
        <OidcProvider>
          <ThemeProvider>
            <Trans>
              <AdobeAnalytics />
              <BrowserRouter>
                <Switch>
                  <Route path='/portal'>
                    <OidcSecure>
                      <UserInfoProvider>
                        <AsyncCustomerPortal />
                      </UserInfoProvider>
                    </OidcSecure>
                  </Route>
                  <Route path='/abp-sso'>
                    <OidcSecure>
                      <UserInfoProvider>
                        <AsyncCustomerPortal />
                      </UserInfoProvider>
                    </OidcSecure>
                  </Route>
                  <Route path='/'>
                    <Redirect to='/portal' />
                  </Route>
                </Switch>
              </BrowserRouter>
            </Trans>
          </ThemeProvider>
        </OidcProvider>
      </PermissionsProvider>
    </Suspense>
  );
}

export default App;
