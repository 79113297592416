import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  AppColors,
  FillStyles,
  getFillableElementStyle,
  getFontStyleClass,
  getTextElementStyle,
  Sizes,
  TextStyles
} from '../../style/AppStyles';
import withInteraction from '../../util/hoc/WithInteraction.util';
import withTooltip from '../../util/hoc/WithTooltip.util';
import TranslateUtil from '../../util/Translate.util';

import './Button.scss';

const buttonTypes = ['button', 'submit', 'reset'];

class ButtonComponent extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    tKey: PropTypes.string,
    type: PropTypes.oneOf(buttonTypes),
    buttonColor: PropTypes.oneOf(AppColors),
    textColor: PropTypes.oneOf(AppColors),
    textStyle: PropTypes.oneOf(TextStyles),
    borderColor: PropTypes.oneOf(AppColors),
    fillStyle: PropTypes.oneOf(FillStyles),
    fillColor: PropTypes.oneOf(AppColors),
    size: PropTypes.oneOf(Sizes),
    disabled: PropTypes.bool
  };

  static defaultProps = {
    type: 'button',
    buttonColor: 'text',
    textColor: 'none',
    textStyle: 'none',
    borderColor: 'none',
    fillStyle: 'solid',
    fillColor: 'none',
    size: 'normal',
    disabled: false
  };

  constructor(props, context) {
    super(props, context);
    this.translate = new TranslateUtil(props.tKey);
  }

  componentDidUpdate() {
    this.translate = new TranslateUtil(this.props.tKey);
  }

  render() {
    const {
      className,
      tKey,
      type,
      fillStyle,
      size,
      buttonColor,
      textColor,
      textStyle,
      borderColor,
      fillColor,
      disabled,
      buttonState,
      ...restProps
    } = this.props;
    const btnClass = classNames('btn', getFontStyleClass(textStyle), className, fillStyle, size, {
      'btn-active': this.state && this.state.isActive,
      'btn-over': this.state && !this.state.isIgnoringHover && this.state.isHovered
    });
    const renderButtonState = disabled
      ? 'disabled'
      : this.state && this.state.isHovered
      ? 'hover'
      : this.state && this.state.isActive
      ? 'active'
      : 'normal';
    return (
      <button
        className={btnClass}
        style={{
          ...getTextElementStyle(size, textStyle, textColor),
          ...getFillableElementStyle(
            fillStyle,
            disabled ? 'disabled' : buttonColor,
            textColor,
            borderColor,
            fillColor,
            renderButtonState
          )
        }}
        disabled={!!disabled}
        type={this.type}
        ref={(ref) => {
          this.props.onRef && this.props.onRef(ref);
        }}
        {...restProps}
      >
        {this.props.children ? this.props.children : this.translate.translation}
      </button>
    );
  }
}

export default class Button extends withTooltip(withInteraction(ButtonComponent)) {}
